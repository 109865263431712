import React, { useState, useRef } from "react"
import { Link } from "react-router-dom"

import {AiFillPlusSquare, AiFillCloseSquare, AiOutlineDownload} from "react-icons/ai"

const Accordion = ({ title, content, download}) => {
  const [isOpened, setOpened] = useState(false)
  const [height, setHeight] = useState("0px")
  const contentElement = useRef(null)

  const HandleOpening = () => {
    setOpened(!isOpened)
    setHeight(!isOpened ? `${contentElement.current.scrollHeight}px` : "0px")
  }
  return (
    <div onClick={HandleOpening} className="border border-indigo-400 ">
      <div className={" p-4 flex justify-between text-white"}>
        <h4 className="font-semibold text-gray-700">{title}</h4>
        {isOpened ? <AiFillCloseSquare size={30} className="text-goblue"/> : <AiFillPlusSquare className="text-goblue" size={30}/>}
      </div>
      <div
        ref={contentElement}
        style={{ height: height }}
        className="bg-gray-200 overflow-hidden transition-all duration-200 text-gray-500"
      >
        {download? (
            <div>
          {content.map((data, id)=>{
                return (
                    <div key={id} className="flex content-center items-center p-3">
                        <AiOutlineDownload size={20} className="text-goblue mr-2"/>
                            <a href={data.url} className="text-goblue underline">{data.name}</a>
                    </div>
                )
              
            })}
            </div>
        ):(
            <ul className="p-3 ml-5 list-disc">
            {content.map((data, id)=>{
                return <li key={id} className=""><strong>{data.name}</strong> <br></br> {data.desc}</li>
            })}
        </ul>
        )}
        
        {/* <p className="p-4">{content}</p> */}
      </div>
    </div>
  )
}

export default Accordion
