import React from "react";

const FeatureCard = ({ feature }) => {
  return (
    <div className=" border-gray-400 border rounded-sm ">
      <div className="w-full min-h-[280px]">
        <img className="bg-cover bg-center overflow-hidden" src={feature.img} alt={feature.name} />
      </div>
      <div>
        <h3 className="bg-goblue text-white text-center p-2 uppercase font-bold">{feature.name}</h3>
        <p className="p-3">{feature.desc}</p>
      </div>
    </div>
  );
};

export default FeatureCard;
