import React from "react";
import banner1 from "../images/banner/banner-1.jpg";
import banner2 from "../images/banner/banner2.jpg";
import banner3 from "../images/banner/banner3.jpg";

const Carasol = () => {
  return (
    <div className="">
      <div
        id="carouselExampleRide"
        className="carousel slide"
        data-bs-ride="true"
      >
        <div className="carousel-inner">
          <div className="carousel-item active w-full ">
            <img src={banner1} className="d-block  object-contain" alt="..." />
          </div>
          <div className="carousel-item active w-full ">
            <img src={banner2} className="d-block  object-contain" alt="..." />
          </div>
          <div className="carousel-item active w-full ">
            <img src={banner3} className="d-block  object-contain" alt="..." />
          </div>
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleRide"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleRide"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Carasol;
