import React, { useState } from 'react'
import contact from '../images/connectus.png'
import { FaHome } from 'react-icons/fa'
import { FiPhoneCall } from 'react-icons/fi'
import { ImLocation } from 'react-icons/im'
import { SlUserFollowing } from 'react-icons/sl'
import { BsFacebook, BsInstagram, BsTwitter, BsYoutube, BsLinkedin } from 'react-icons/bs'
import Hero from '../components/Hero'

const ContactUs = () => {


  return (
    <div className='bg-[#F1F5F8]' >
     <Hero title={"Contact Us"} desc={"We're here to help you with anything. Do not hesitate to call us."} />
      
      <div className='flex flex-wrap gap-10 md:gap-24 justify-center px-20 mt-4 m-1 text-center p-7'>

        <div>

          <h1 className='font-bold text-center text-2xl'> Head office</h1>
          <h3 className='pt-2'>Naya Bato , Dhobighat, Lalitpur, Nepal</h3>
          <h3 className='pt-2'>Phone: <a className='text-[#1457a6]' href="tel:01-5539009">01-5539009</a> / <a className='text-[#1457a6]' href="tel:9802350695">9802350695</a></h3>

        </div>
        <div>

          <h1 className='font-bold text-center text-2xl'>For Service</h1>
          <h3 className='pt-2'>Kathmandu Valley: <a className='text-[#1457a6]' href="tel:01-5539009">01-5539009</a> <a className='text-[#1457a6]' href="tel:9801054423">9801054423</a></h3>
          <h3 className='pt-2'>Ithari: <a className='text-[#1457a6]' href="tel:9820757958">9820757958</a></h3>
          <h3 className='pt-2'>Birgung: <a className='text-[#1457a6]' href="tel:9802098830">9802098830</a></h3>
          <h3 className='pt-2'>Nepalgung: <a className='text-[#1457a6]' href="tel:9801467677">9801467677</a></h3>
      
    
        </div>

        <div>
          <h1 className='   font-bold text-center text-2xl'>Follow us</h1>  <br />
          <div className='flex gap-2   items-center justify-center'>
          <a href='https://www.facebook.com/goenergynepal/'>   <BsFacebook size={24} />  </a>
            {/* <a href=''>  <BsInstagram size={24} /> </a>
   
            <a href=''>  < BsYoutube size={24} /> </a>
            <a href=''>  < BsLinkedin size={24} /> </a>  */}
          </div>
        </div>
      </div> 
      
      <div className='h-[70vh] w-full'>
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14133.046548951997!2d85.3030768!3d27.6783073!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcc33ce920abc93f0!2sG.O%20Energy%20Pvt.%20Ltd.!5e0!3m2!1sen!2snp!4v1675755018047!5m2!1sen!2snp" width='100%' height='100%' loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div >

  )
}

export default ContactUs