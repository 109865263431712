import React from 'react'
import Hero from '../../components/Hero'
import Repair from '../../images/Repair.png'
const HomeService = () => {

    const desc = "Our customer service and support team provide our customers all the technical guidance related to maintenance of luminous Home Ups, Batteries and other products. We also provide expert after sales support and home service maintenance facilities for troubleshooting Luminous Home Ups and Batteries."
    return (
        <div>


            <Hero title={"Home Services"} desc={desc} />
            <div className='md:felx justify-center items-center  md:m-10'>
                <div className='  md:flex flex-w gap-20 justify-center'>
                    <div className=' mx-24 my-5 md:flex items-center '>
                        <div className=' bg-slate-100 border-2 rounded-md p-2 w-full felx justify-center     '>
                            <img src={Repair} className="w-[12rem] pl-5" />
                            <h2 className='font-bold text-xl pl-7'> Repair Services</h2>
                            <p className=' text-sm pl-10 '> 01 - 52375560044  <br />
                                +977 - 980200566  <br />
                                +977-9801088130</p>
                        </div>
                    </div>
                    <div className=' p-5 md:p-9  border-2 bg-slate-100    '>
                        <h1 className='font-bold pt-7 text-2xl leading-loose  '>  Maintenance Tips</h1>
                        <ul className='list-disc mt-4 leading-8'>
                            <li> Always use a well ventilated area for inverter installation.</li>
                            <li> Keep the battery terminals corrosion free and rust free.</li>
                            <li>Always keep the surface and sides of battery clean and dust free.</li>
                            <li> From safety point of view, install the inverter at safe places in your  <br />home which is out of the reach of children or a less used area..</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default HomeService