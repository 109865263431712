import React from "react";
import Accordion from "./Accordion";
import FeatureCard from "./FeatureCard";
import ModelCard from "./ModelCard";
const ProductDetails = ({ productDetail }) => {
  return (
    <div>
      <div>
        <img src={productDetail.banner} alt="" />
        <h2 className="uppercase text-3xl font-extrabold text-goblue text-center pt-4">
          {productDetail.title}
        </h2>
        <hr class="w-48 h-1 mx-auto my-2 bg-goblue mb-3" />
        <p className="px-10 text-center">{productDetail.desc}</p>
      </div>
      {productDetail.ex_features.length === 0 ? (
        ""
      ) : (
        <div className=" p-5">
          <h2 className="uppercase text-3xl font-extrabold text-goblue text-center ">
            Exclusive Features
          </h2>
          <hr class="w-48 h-1 mx-auto my-2 bg-goblue mb-3" />

          <div className="grid md:grid-cols-3 grid-cols-1 gap-5">
            {productDetail.ex_features.map((feature, id) => {
              return <FeatureCard key={id} feature={feature} />;
            })}
          </div>
        </div>
      )}

      {productDetail.models.length === 0 ? (
        ""
      ) : (
        <div className="p-5">
          <h2 className="uppercase text-3xl font-extrabold text-goblue text-center ">
            Models
          </h2>
          <hr class="w-48 h-1 mx-auto my-2 bg-goblue mb-3" />
          <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
            {productDetail.models.map((model, id) => {
              return <ModelCard model={model} key={id} />;
            })}
          </div>
        </div>
      )}

      <div className="p-5">
        {productDetail.advantages.length === 0 ? (
          ""
        ) : (
          <Accordion title="Advantages" content={productDetail.advantages} />
        )}

        {productDetail.features.length === 0 ? (
          ""
        ) : (
          <Accordion title="Features" content={productDetail.features} />
        )}

        {productDetail.portfolio_catalogue.length === 0 ? (
          ""
        ) : (
          <Accordion
            title="Download Portfolio Catelogue"
            content={productDetail.portfolio_catalogue}
            download={true}
          />
        )}

        {productDetail.certification.length === 0 ? (
          ""
        ) : (
          <Accordion
            title="Certification"
            content={productDetail.certification}
            download={true}
          />
        )}
      </div>
    </div>
  );
};

export default ProductDetails;
