import React, { useState, useEffect } from "react";

import { FiChevronDown } from "react-icons/fi";
import { Link, NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import {BiPhoneCall} from "react-icons/bi"
import logo from "../images/gosolar.png";

const Navbar = () => {
  const [aboutCard, setAboutCard] = useState(false);
  const [productCard, setProductCard] = useState(false);
  const [courseCard, setCourseCard] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <section className="z-30 h-auto shadow-sm">
      <div className="flex justify-between items-center px-8 md:px-14 relative">
        <div className="">
          <Link to="/">
            <img src={logo} alt="logo" className=" object-contain h-10" />
          </Link>
        </div>

        <div className="md:hidden">
          {open ? (
            <RxCross2 className="text-2xl" onClick={() => setOpen(false)} />
          ) : (
            <GiHamburgerMenu
              className="text-2xl"
              onClick={() => setOpen(true)}
            />
          )}
        </div>

        <div
          className={`md:flex md:items-center  gap-14 font-bold text-gray-700 absolute z-50 md:z-auto md:[-1] md:static md:w-auto left-0 w-full bg-white px-8 py-2 md:px-0 md:py-0 border-2 md:border-0 md:bg-none transition-all duration-500 ease-in ${
            open ? "top-100" : "top-[-490px]"
          }`}
        >
          <div className="py-1">
            <NavLink to="/" className=" cursor-pointer">
              Home
            </NavLink>
          </div>
          <div
            onMouseEnter={() => setAboutCard(true)}
            onMouseLeave={() => setAboutCard(false)}
            className="relative"
          >
            <div className="flex items-center gap-2 cursor-pointer py-1">
              About
              <FiChevronDown />
            </div>
            {/* Hover Card */}
            {aboutCard && (
              <div className="md:absolute top-6 -left-10 z-10 w-[15rem] bg-white text-gray-800 p-4 border-t-2 0 shadow-md">
                <div className=" flex flex-col transition-all ">
                  <NavLink
                    to="/aboutus"
                    className=" hover:bg-gray-100 px-2 py-1 cursor-pointer "
                  >
                    <div id="so">
                      {" "}
                      <span
                        onClick={() => {
                          setAboutCard(false);
                          setOpen(false);
                        }}
                      >
                        About Us
                      </span>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/aboutus#scroll"
                    className=" hover:bg-gray-100 px-2 py-1 cursor-pointer"
                  >
                    <span
                      onClick={() => {
                        setAboutCard(false);
                        setOpen(false);
                        window.scrollTo ({
                          top: window.innerHeight / .7, // scroll to the middle of the page
                          left: 0,
                          behavior: 'smooth' // smooth scrolling animation
                        })
                      }}
                    >
                      {" "}
                      Our Technology Partner
                    </span>
                  </NavLink>
                </div>
              </div>
            )}
          </div>
          <div
            onMouseEnter={() => setProductCard(true)}
            onMouseLeave={() => setProductCard(false)}
            className="relative"
          >
            <div className="flex items-center gap-2 py-1">
              Product
              <FiChevronDown />
            </div>
            {/* Hover Card */}
            {productCard && (
              <div className="md:absolute top-6 -left-10 z-10 w-[14rem] bg-white text-gray-800 p-4 border-t-2 0 shadow-md">
                <div className=" flex flex-col ">
                  <NavLink
                    to="/product/inverter-battery"
                    className=" hover:bg-gray-100 px-2 py-1 underline-offset-1"
                  >
                    <div id="so">
                      {" "}
                      <span
                        onClick={() => {
                          setProductCard(false);
                          setOpen(false);
                        }}
                      >
                        {" "}
                        Inverter Batteries
                      </span>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/product/vrla-battery"
                    className=" hover:bg-gray-100 px-2 py-1 underline-offset-1"
                  >
                    <span
                      onClick={() => {
                        setProductCard(false);
                        setOpen(false);
                      }}
                    >
                      {" "}
                      VRLA Battery
                    </span>
                  </NavLink>
                  {/* <NavLink
                    to="/product/lithium-battery"
                    className="hover:bg-gray-100 px-2 py-1 underline-offset-1"
                  >
                    <span
                      onClick={() => {
                        setProductCard(false);
                        setOpen(false);
                      }}
                    >
                      {" "}
                      Lithium Battery
                    </span>
                  </NavLink> */}
                  {/* <NavLink
                    to="/product/automative-battery"
                    className="hover:bg-gray-100 px-2 py-1 underline-offset-1"
                  >
                    <span
                      onClick={() => {
                        setProductCard(false);
                        setOpen(false);
                      }}
                    >
                      {" "}
                      Automative Battery
                    </span>
                  </NavLink> */}
                  <NavLink
                    to="/product/e-rickshaw-battery"
                    className="hover:bg-gray-100 px-2 py-1 underline-offset-1"
                  >
                    <span
                      onClick={() => {
                        setProductCard(false);
                        setOpen(false);
                      }}
                    >
                      {" "}
                      E-Rickshaw battery
                    </span>
                  </NavLink>
                </div>
              </div>
            )}
          </div>

          <div
            onMouseEnter={() => setCourseCard(true)}
            onMouseLeave={() => setCourseCard(false)}
            className="relative"
          >
            <div className="flex items-center gap-2 py-1">
              Support
              <FiChevronDown />
            </div>
            {/* Hover Card */}
            {courseCard && (
              <div className="md:absolute top-6 -left-10 z-10 w-[14rem] bg-white text-gray-800 p-4 border-t-2 0 shadow-md">
                <div className=" flex flex-col ">
                  <NavLink
                    to="/contactus"
                    className=" hover:bg-gray-100 px-2 py-1 underline-offset-1"
                  >
                    <div id="so">
                      {" "}
                      <span
                        onClick={() => {
                          setCourseCard(false);
                          setOpen(false);
                        }}
                      >
                        {" "}
                        Contact Us
                      </span>
                    </div>
                  </NavLink>
                  <NavLink
                    to="/support/Inquiry"
                    className=" hover:bg-gray-100 px-2 py-1 underline-offset-1"
                  >
                    <span
                      onClick={() => {
                        setCourseCard(false);
                        setOpen(false);
                      }}
                    >
                      {" "}
                      Customer Enquiry
                    </span>
                  </NavLink>
                  <NavLink
                    to="/support/Feedback"
                    className="hover:bg-gray-100 px-2 py-1 underline-offset-1"
                  >
                    <span
                      onClick={() => {
                        setCourseCard(false);
                        setOpen(false);
                      }}
                    >
                      {" "}
                      Feedback and Contact
                    </span>
                  </NavLink>
                </div>
              </div>
            )}
          </div>
          <div className=" bg-[#3281C3]  ml-0 md:ml-10 my-3 md:my-0 lg:my-0 flex gap-2 content-center items-center px-6 rounded-full">
          <BiPhoneCall size={20} className="text-white"/>
            <a
              href="tel:9802350695"
              className="py-2 text-white rounded-3xl"
            >
              
              9802350695
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Navbar;
