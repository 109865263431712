import React from "react";
import about from "../images/about/banner.webp";
import { FiLink, FiCheckCircle } from "react-icons/fi";
import {BsPeople } from "react-icons/bs";
import {HiOutlineAcademicCap} from "react-icons/hi";

import okayaLogo from "../images/logos/okaya.png"
import goLogo from "../images/logos/go.png"
const AboutUs = () => {
  return (
    <div className=" flex-col md:items-center ">
      <img src={about} className="    md:w-full object-contain" />

      <div className="flex flex-col items-center justify-center  m-4">
        <div className=" mt-5 text-center  w-[80vw]">
          <h1 className="font-bold text-3xl"> About Go Energy</h1>
          <hr class="w-48 h-1 mx-auto my-2 bg-goblue mb-3"/>
          
          <div className="flex items-center flex-col-reverse md:flex-row lg:flex-row">
            <p className=" mt-7 text-left text-xl">
            {" "}
            G.O Energy Pvt.Ltd. operates under the Golcha organization a multifaceted conglomerate. G.O Energy Pvt.Ltd. is one of the leading and fastest growing trading companies that strongly believes in enhancing Nepal’s current energy sector scenario along with its sustainable growth.

The company was established in 2019 AD and is the sole distributor of OKaya Inverters, Batteries, Stabilizers and its accessories .The company offers a wide variety of alternative power backup solution products and has largest sales network across the whole country with around 200 plus  dealers and service centers in 7 major cities catering the full range service needs of our valued customer.
          </p>
            <img src={goLogo} alt="go energy logo" className="px-9 h-36" />
          </div>
        </div>
        <div className="mt-5 text-center w-[80vw] " >
          <h1 className="font-bold text-3xl text-center" id="scroll">
            {" "}
            Our Technology Partner{" "}
          </h1>
          <hr class="w-48 h-1 mx-auto my-2 bg-goblue mb-3"/>
          <div className="flex  items-center flex-col md:flex-row lg:flex-row">
            <img src={okayaLogo} alt="okaya energy logo" className="px-9 h-fit"/>
            <p className="mt-7 text-left text-xl">
         
            OKAYA, the pioneer in the battery manufacturing industry, has been a
            symbol of trust and quality for years. Okaya is known for providing
            a wide range of batteries to meet the different energy requirements
            of the consumer. The varied product range of batteries is suitable
            for all kinds of applications, like Tubular Battery- Inverter
            Battery and Solar Battery, SMF Battery, E-Rickshaw Battery, Lithium
            and EV charging solutions. Okaya is the sole manufacturer of 100%
            Tubular Batteries, which are considered as best for power backup
            needs, used both in Inverter and Solar Batteries. .
          </p>
          </div>
          
          {/* <div>
          <h2 className="mt-3 font-bold">
            {" "}
            The batteries manufactured by Okaya are:
          </h2>
          <ul>
          <li>Eco-friendly</li>
          <li> Reliable </li>
          <li> Long-Lasting </li>
          <li> Trusted for robust backups </li>
          </ul>

          </div> */}
        </div>
      </div>
      <div className=" pt-20  ">
        <div className=" flex justify-center flex-col">
          <h1 className="font-bold text-3xl text-center"> Our values</h1>
          <hr class="w-48 h-1 mx-auto my-2 bg-goblue mb-3"/>
        </div>

        <div className=" flex-row-reverse  md:flex gap-10 justify-center   px-20 py-10">
          <div className=" w-full md:w-[50%] flex items-center justify-center flex-col">
            <div className=" bg-gray-100 rounded-md w-10 h-10 flex justify-center items-center">
              <FiCheckCircle className="text-goblue text-center" size={20}/>
            </div>
            <div>
              <h3 className="font-bold text-xl mt-2 text-center"> System</h3>
              <p className="text-center">
                {" "}
                Be a system and process driven company that constantly invests
                in its physical infrastructure, people, technology and all
                procedural systems that drive efficiency and excellence
              </p>
            </div>
          </div>
          <div className=" w-full md:w-[50%] flex items-center justify-center flex-col">
          <div className=" bg-gray-100 rounded-md w-10 h-10 flex justify-center items-center">
              <FiLink className="text-goblue" size={20}/>
            </div>
            <div>
            <h3 className="font-bold text-xl mt-2 text-center"> Integrity</h3>
            <p className="text-center">
              Maintain high ethical standards and compliance in conducting
              business
            </p>
            </div>
            
          </div>
        </div>

        <div className="   flex-row-reverse  md:flex gap-10   pb-5 px-20 py-7 ">
          <div className="   w-full md:w-[50%] flex items-center justify-center flex-col">
          <div className=" bg-gray-100 rounded-md w-10 h-10 flex justify-center items-center">
              <HiOutlineAcademicCap size={20} className="text-goblue"/>
            </div>
            <div>
            <h3 className="font-bold text-xl mt-2 text-center">Learning</h3>
            <p className="text-center">
              Strongly emphasize the need to remain ahead of competition and be
              current with global standards and benchmarks in training employees
            </p>
            </div>
            
          </div>
          <div className=" w-full md:w-[50%] flex items-center justify-center flex-col">
          <div className=" bg-gray-100 rounded-md w-10 h-10 flex justify-center items-center">
              <BsPeople className="text-goblue" size={20}/>
            </div>
            <div>
            <h3 className="font-bold text-xl mt-2 text-center"> Unity</h3>
            <p className="text-center">
              Maintain and improve open communication platform, encourage team
              work, respect all and recognize excellence in performance
            </p>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;


// Empty Commit 
