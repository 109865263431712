import React from 'react'

const Hero = ({title, desc}) => {
    return (
        <div className=' bg-[#2670B9] md:px-10 py-20'>
            <div className=' px-10 text-white'>
                <h1 className='text-4xl font-bold  '>{title}</h1>
                <p className=' h-auto mt-2'>{desc}</p>
            </div>
        </div>
    )
}

export default Hero