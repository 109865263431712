import React from "react";

const ModelCard = ({ model }) => {
  return (
    <div className=" border-gray-400 border rounded-sm ">
      <div className="w-full min-h-[280px]">
        <img className="bg-cover bg-center overflow-hidden" src={model.image} alt={model.name} />
      </div>
      <div>
        <h3 className="bg-goblue text-white text-center p-2 uppercase font-bold">{model.name}</h3>
        {model.specs.length===0?'':<>
        <p className="px-3 pt-3 font-semibold">Specfications</p>

        <ul className="px-3 pb-3">
            {model.specs.map((spec,id)=>{
                return <li key={id}>{spec.name}</li>
            })}
        </ul>
        </>}
        
      </div>
    </div>
  );
};

export default ModelCard;
