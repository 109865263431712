import inverter from '../images/home/inverter.jpg'
import vrla from '../images/home/vlra.jpg';
import twowheel from '../images/twowheel/lithium.jpg'
import solarr from '../images/home/solar.jpg'
import battery from '../images/battery.jpg'
import solar from '../images/solarr.jpg'
import one from '../images/one.png'
import two from '../images/two.png'
import three from '../images/three.png'
import four from '../images/four.png'

import automobile from '../images/automative/auto.webp'
import riksha from '../images/home/riksha.png'

import wheel from '../images/wheel.jpg'
import tube from '../images/tublar/haditall200ah.jpg'

import lithium from '../images/lithium/lithium.jpg'


export const items = [
    {
        name: "Inverter Battery",
        content: "A superior quality is what distinguishes the extraordinary product from an ordinary one. All the inverter batteries may typically work the same but what make OKAYA batteries exceptional is its cutting edge technology, premier quality raw material and in-house manufacturing. With OKAYA batteries forget about those power cuts. The Batteries are made using the exceptional 100% HADI tubular technology which provides unmatchable performance.",
        image: inverter,
        link:"inverter-battery",

    },
    {
        name: "SMF/VRLA battery",
        content: "SMF (Sealed Maintenance Free) batteries also known as the Valve Regulated Lead Acid (VRLA) batteries. These flat plate batteries that do not require topping-up and normally do not emit any fumes or gases on a continuous basis. They are completely sealed and therefore eliminate the risk of acid spillage during transportation. Due to their construction these can be mounted in any orientation and do not require constant maintenance. These are used for high end applications of standby power like UPS, Electric Converter, Railway Communications, Security Systems etc.",
        image:vrla,
        flag: "left",
        link:"vrla-battery"
    },
    
    // {
    //     name: "lithium battery",
    //     content: "Lithium batteries are an ideal match for varied applications like solar energy storage, powering E-Vehicles, Telecom & Energy storage systems. In Lithium batteries Lithium is used as Anode, the charge moves from anode to cathode during discharge and cathode to anode during charging.",
    //     image:lithium,
    //     flag: "",
    //     link:"lithium-battery"
    // },
    {
        name: "Solar battery ",
        content: "Solar Batteries as the name suggests are powered by the solar energy. It is a rechargeable battery that integrates a solar cell with battery power storage. It is also a tubular battery and uses captive power for its energy source. Over the last decades, the focus on solar photovoltaic systems usage to meet the growing clean power demand globally has increased manifold. ",
        image:solarr,
        flag: "",
        link:"solar-battery"
    },
    {
        name: "Two wheelers battery ",
        content: "Two-wheeler batteries are rechargeable batteries specifically designed for use in motorcycles and scooters. They provide electrical power to start the engine and run the electrical systems of the vehicle. Two-wheeler batteries are typically lead-acid batteries and come in various sizes to match the specific requirements of different two-wheeler models. ",
        image:  twowheel,
        flag: "left",
        link:"two-wheelers-battery"
    },
    {
        name: "E-Rickshaw battery ",
        content: ". E-rickshaw battery is gaining more and more popularity these days as it is designed keeping all the necessary points in mind that are related to users and their requirements. Okaya commits to serve the users in the best possible way and following the same since long years. Each and every battery undergoes some specific test so as to confirm the uninterrupted services to users. Products are beautifully designed and tested up to the extent so as they excel the most as far as its performance is concerned.",
        image:   riksha,
        flag: "",
        link:"e-rickshaw-battery"
    },

    {
        name: "Tublar battery ",
        content: "Tubular batteries are a type of lead-acid battery with a longer life span, low maintenance, and high reliability. They have a tubular plate design for higher power output and are commonly used in solar power and backup power systems.",
        image:tube,
        flag: "left",
        link:"tubular-battery"
    },



   

]


export const invertor =
{
    title: " iCon 1100 ",
    tagline: "",
    desc: "Sunworth polycrystalline solar panels are Renewable Energy Test Station (RETS) and ISO9001:2008 international quality management system certified solar panels. Sunworth polycrystalline solar panels are manufactured with the state of art technology to meet peoples need for alternative power.",
    range: "Ranges Available: 10 Wp - 250 Wp",
    image: inverter,
    features: [
        "Pure Sine Wave technology",
        "Capacity - 900 VA, Rated Power - 756 W",
        " No Open wires as battery is encapsulated in the inverter asking it completely safe for children",

    ],

     extraFeatures: [
        {
            id: 0,
            img: one,
            text: "   Pure Sine Wave",
        },
        {
            id: 1,
            img: two,
            text: "  Hassel Free Water Top Up ",
        },
        {
            id: 2,
            img: three,
            text: "  Fast Battery Charging",
        },
        {
            id: 3,
            img: four,
            text: " Safe For Children  ",
        },
    ]

}



export const batteries =
{

    title: " Industrial Tubular Battery",
    disc: "Consistent players are the superior performers",
    desc: "Inverlast Tall tubular range of batteries are next generation Tall Tubular batteries which are designed to withstand long and frequent power cuts.",
    range: "Ranges available: 100Ah to 220AhRanges Available: 10 Wp - 250 Wp",
    image: battery,
    features: [
        "Extremely high purity, corrosion-resistant proprietary spine alloy composition ensures longer battery service life",
        "Tubular plate construction ensures uniform distribution of positive active material for extremely long life and superior performance",
        "Extra-strong, and flexible oxidation-resistant gauntlet for higher performance and extremely long life",
        ,
    ],

    extraFeatures: [
        {
            id: 0,
            img: one,
            text: "   Pure Sine Wave",
        },
        {
            id: 1,
            img: two,
            text: "  Hassel Free Water Top Up ",
        },
        {
            id: 2,
            img: three,
            text: "  Fast Battery Charging",
        },
        {
            id: 3,
            img: four,
            text: " Safe For Children  ",
        },
    ]
}



export const solarpannel =
{

    title: "Sunworth Poly PV Panels",
    disc: "",
    des: "Sunworth polycrystalline solar panels are Renewable Energy Test Station (RETS) and ISO9001:2008 international quality management system certified solar panels. Sunworth polycrystalline solar panels are manufactured with the state of art technology to meet peoples need for alternative power.",
    image: solar,
    range: "Ranges Available: 10 Wp - 250 Wp",
    features: [
        "High quality raw materials, advanced encapsulation.",
        "Positive tolerence.",
        "Excellent performance under weak light conditions",
    ],
    extraFeatures: [
        {
            id: 0,
            img: one,
            text: "   Pure Sine Wave",
        },
        {
            id: 1,
            img: two,
            text: "  Hassel Free Water Top Up ",
        },
        {
            id: 2,
            img: three,
            text: "  Fast Battery Charging",
        },
        {
            id: 3,
            img: four,
            text: " Safe For Children  ",
        },
    ]
}



{/*card data */ }


export const solarlist = [
    {
        image: solar,
        name: "Sunworth Poly PV Panels",
        price: "2000",
        val: "go"

    },
    {
        image: solar,
        name: "tv solar",
        price: "2000",
        val:"goo"
    },
]

export const inverterlist = [
    {
        image: inverter,
        name: "Icon 55",
        price: "3000",
        val: "go"



    },
    {
        image: inverter,
        name: "Inveter",
        price: "1000",
        val:"goo"


    },
]


export const batterylist = [
    {
        image: battery,
        name: "Torch battery",
        price: "1000",
        val:"go"


    },
    {
        image: battery,
        name: "Industrial Tubular Battery",
        price: "1000",
        val:"goo"


    },
]
