import React from 'react'
import { useParams } from "react-router-dom";
import ProductDetails from '../components/ProductDetails';
import {Products} from "../data/Products"

const Product = () => {
    const { slug } = useParams();
  return (
    <div>
        {Products.map((product, id)=>{
           if(product.slug === slug){
            return <ProductDetails key={id} productDetail={product}/>
           }
           
        })}
        
 
    </div>
  )
}

export default Product