import React, { useState } from "react";

import Hero from "../../components/Hero";
import Form from "./Form";

const Inquiry = () => {
  return (
    <div>
      <Hero
        title={"Customer Inquiry"}
        desc={
          "We're here to help you with anything. Do not hesitate to write us."
        }
       
      />

        <Form type ={"Inquiry"}/>

    
    </div>
  );
};

export default Inquiry;
