import React from 'react'
import Hero from '../components/Hero'

const Terms = () => {
  return (
    <div>

<Hero title={"Terms and Condition"} desc={"Go Energy Pvt. Ltd."} />
<div className='w-[80vw] flex flex-col items-center justify-center mx-auto my-10'>
  <ol className=' list-outside list-decimal'>
    <li>
    Introduction
    </li>
    <p>This website is owned and operated by Go Energy Pvt. Ltd. (hereinafter referred to as "Go Energy", "we", "us" or "our"). The following terms and conditions (these "Terms of Use") govern your access to and use of the Go Energy website, including any content, functionality, and services offered on or through the website (the "Website").</p>
    <li>Acceptance of Terms of Use</li>
    <p>By accessing or using the Website, you agree to be bound by these Terms of Use. If you do not agree to these Terms of Use, you may not access or use the Website.</p>
    <li>Changes to Terms of Use</li>
    <p>Go Energy reserves the right, at its sole discretion, to modify or replace these Terms of Use at any time. If we make changes to these Terms of Use, we will post the updated Terms of Use on the Website and update the "Effective Date" at the top of this page. Your continued use of the Website following the posting of any changes constitutes acceptance of those changes.</p>
    <li>Accessing the Website and Account Security</li>
    <p>We reserve the right to withdraw or amend the Website, and any service or material we provide on the Website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website, to users, including registered users.</p>
    <li>User Conduct</li>
    <p>By using the Website, you agree not to engage in any activity that, in our sole discretion, disrupts, interferes with or is harmful to the Website, systems, or other users. This includes, without limitation, using the Website to transmit any computer viruses, worms, defects, Trojan horses or other items of a destructive nature, engaging in spamming, flooding, harvesting of email addresses or other personal information, or otherwise engaging in any unethical or unlawful conduct.</p>
    <li>Proprietary Rights</li>
    <p>The content on the Website, including without limitation, text, software, scripts, graphics, photos, sounds, music, videos, interactive features and the like ("Content") and the trademarks, service marks and logos contained therein ("Marks"), are owned by or licensed to Go Energy, subject to copyright and other intellectual property rights under Indian and foreign laws and international conventions. Content on the Website is provided to you "AS IS" for your information and personal use only and may not be used, copied, reproduced, distributed, transmitted, broadcast, displayed, sold, licensed, or otherwise exploited for any other purposes whatsoever without the prior written consent of Go Energy.</p>
    <li>Disclaimers and Limitation of Liability</li>
    <p>THE CONTENT AND SERVICES ON THE WEBSITE ARE PROVIDED "AS IS" AND WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE CONTENT OR SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
    <li>Limitation of Liability</li>
    <p>GO ENERGY, ITS AFFILIATES, LICENSORS, SERVICE PROVIDERS, CONTENT PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, AND DIRECTORS WILL NOT BE LIABLE FOR ANY INCIDENTAL, DIRECT, INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY, OR OTHER DAMAGES, INCLUDING LOSS OF REVENUE OR INCOME, PAIN AND SUFFERING, EMOTIONAL DISTRESS, OR SIMILAR DAMAGES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
    <li>Indemnification</li>
    <p>You agree to indemnify, defend, and hold harmless Go Energy, its affiliates, licensors, service providers, content providers, employees, agents, officers, and directors from and against all losses, expenses, damages, and costs, including reasonable attorneys' fees, resulting from any violation of these Terms of Use or any activity related to your account (including negligent or wrongful conduct) by you or any other person accessing the Website using your account.</p>
    <li>Contact Information</li>
    <p>If you have any questions about these Terms of Use, please contact us at: <a href="mailto:info@goenergy.com" className='text-goblue'>info@goenergy.com</a></p>
    <br />
    <p className='text-bold'>Effective Date: February 13, 2023 <br />

These Terms of Use constitute the entire agreement between you and Go Energy with respect to the use of the Website.</p>
  </ol>
</div>
    </div>
  )
}

export default Terms