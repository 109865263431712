// Invertor Images and files
import inverter from "../images/inverter/inverter.jpg";
import inverterBanner from "../images/inverter/inverter-banner.webp";
import inverterFeature2 from "../images/inverter/inverter-feature-2.jpg";
import inverterFeature3 from "../images/inverter/inverter-feature-3.jpg";
import inverterFeature4 from "../images/inverter/inverter-feature-4.jpg";
import inverterFeature5 from "../images/inverter/inverter-feature-5.jpg";
import inverterFeature6 from "../images/inverter/inverter-feature-6.jpg";
import testreport1 from "../images/inverter/pdf/Testreport.pdf";
import portfolio from "../images/inverter/pdf/portfolio.pdf";

// Inverter Models
import inverter100h from "../images/inverter/models/100ah.jpg";
import inverter80h from "../images/inverter/models/80ah.jpg";
import invertertall100h from "../images/inverter/models/tall100ah.jpg";
import inverterhaditall150ah from "../images/inverter/models/haditall150ah.jpg";
import inverterjumbo150ah from "../images/inverter/models/jumbo150ah.jpg";
import invertertall150ah from "../images/inverter/models/tall150ah.jpg";
import inverterhaditall160ah from "../images/inverter/models/haditall160ah.jpg";
import inverterjumbo160ah from "../images/inverter/models/jumbo160ah.jpg";
import inverterhaditall165ah from "../images/inverter/models/haditall165ah.jpg";
import inverterhaditall200ah from "../images/inverter/models/haditall200ah.jpg";

// VRLA Images and files
import vrlaBanner from "../images/vrla/vrla.webp";
import vrlaFeature1 from "../images/vrla/feature1.jpg";
import vrlaFeature2 from "../images/vrla/feature2.jpg";
import vrlaFeature3 from "../images/vrla/feature3.jpg";
import vrlaFeature4 from "../images/vrla/feature4.jpg";
import vlraPortfolio from "../images/vrla/pdf/vlra-portfolio.pdf";
import vlraReport from "../images/vrla/pdf/vlra-report.pdf";
// VLRA Models
import ob28 from "../images/vrla/models/ob28.png";
import ob45 from "../images/vrla/models/ob45.png";
import ob65 from "../images/vrla/models/ob65.png";
import ob100 from "../images/vrla/models/ob100.png";
import ob150 from "../images/vrla/models/ob150.png";
import ob200 from "../images/vrla/models/ob200.png";

// Lithium Images and files
import lithiumBanner from "../images/lithium/Lithium.webp";

// rickshaw Images and files
import rickshawBanner from "../images/rickshaw/riksaw.webp";
import rickshawPortfolio from "../images/rickshaw/pdf/rickshaw-portfolio.pdf";
import rickshawReport from "../images/rickshaw/pdf/rickshaw-certificate.pdf";

// Riksaw Models
import ower1400plus from "../images/rickshaw/models/ower1400plus.jpg";
import ower1409 from "../images/rickshaw/models/ower1409.jpg";
import ower13506 from "../images/rickshaw/models/opert13506.jpg";

// Automative Images and Banner
import autoBanner from "../images/automative/auto-banner.webp";
import autoImg from "../images/automative/auto.webp";

//

export const Products = [
  {
    id: 1,
    title: "Inverter Battery",
    slug: "inverter-battery",
    desc: "A superior quality is what distinguishes the extraordinary product from an ordinary one. All the inverter batteries may typically work the same but what make OKAYA batteries exceptional is its cutting edge technology, premier quality raw material and in-house manufacturing. With OKAYA batteries forget about those power cuts. The Batteries are made using the exceptional 100% HADI tubular technology which provides unmatchable performance. The unique design makes it ultra low maintenance and easy to use. The batteries are manufactured with the latest HADI technology using 99.98% Pure lead and in 30% larger containers which increases the longevity of the batteries which gives a longer back up time and prevents frequent water topping. It employs the Latest PE envelope separator technology to prevent short circuiting.",
    img: inverter,
    banner: inverterBanner,
    advantages: [
      {
        name: "Longer Life",
        desc: "It provides twice the life as compared to the conventional batteries.",
      },
      {
        name: "Extensive Application",
        desc: "Suitable for all your power back up needs.",
      },
      {
        name: "Heat Resistant",
        desc: "High resistance to temperature variation.",
      },
      {
        name: "High Cyclic Application",
        desc: "Best used for frequent and prolonged power outages.",
      },
    ],
    features: [
      { name: "100% Tubular ATT Technology battery" },
      { name: "HPDC technology terminals for durability" },
      { name: "Ceramic Water level indicators for easy to check water level" },
      { name: "Ultra low maintenance" },
      { name: "Paper Less Warranty*" },
      { name: "Best in class after sales service" },
      { name: "Best in class heavy duty" },
      { name: "long backup battery for all conditions/seasons" },
      { name: "All Nepal Service Network" },
    ],
    ex_features: [
      {
        name: "30% Extra Electrolyte",
        desc: "Okaya Batteries have Bigger Containers to store 30% more electrolyte so that you need to do water topping less frequently.",
        img: inverter,
      },
      {
        name: "Hadi Tubular Technology",
        desc: "Okaya make the spines by High Pressure ‘Hadi’ Casting Process at 100 bar. We make spines with low antimony lead alloy with selenium and tin to protect them from anodic corrosion.",
        img: inverterFeature2,
      },
      {
        name: "Latest PE Envelope Separator Technology",
        desc: "Negative Plates are fully covered with Daramic USA polyethylene separators to prevent their shorting with positive plates.",
        img: inverterFeature3,
      },
      {
        name: "99.98% Pure LEAD",
        desc: "Okaya imports the best quality primary lead with 99.98% purity . This ensures longer battery life.",
        img: inverterFeature4,
      },
      {
        name: "Advanced Grid Pasting Compound",
        desc: "Okaya's Advanced Grid Pasting Compound ensures quick recharging of the battery in frequent power outages.",
        img: inverterFeature5,
      },

      {
        name: "Ceramic Water Level Management",
        desc: "Hydrogen vapors are trapped back by Ceramic Vent Plugs and condensed to water. This ensures cleanliness and safety along with minimal water loss. Hence, water topping requirement is less.",
        img: inverterFeature6,
      },
    ],
    models: [
      {
        id: 1,
        name: "OKAYA JUMBO TUBULAR 100AH INVERTER BATTERY (24+24 MONTHS WARRANTY)",
        image: inverter100h,
        specs: [
          { name: "Capacity @C20: 100Ah" },
          {
            name: "Dimensions: Length*Width*Total Height = 535 * 220 * 283 mm (±2 mm)",
          },
          { name: "Warranty: 48 months*" },
        ],
      },
      {
        id: 2,
        name: "OKAYA HADI TALL TUBULAR 100AH INVERTER BATTERY (24+24 MONTHS WARRANTY)",
        image: invertertall100h,
        specs: [
          { name: "Capacity @C20: 100Ah" },
          {
            name: "Dimensions: Length*Width*Total Height = 510 * 190 * 412 mm (±2 mm)",
          },
          { name: "Warranty: 48 months*" },
        ],
      },
      {
        id: 3,
        name: "OKAYA TALL TUBULAR 150AH INVERTER BATTERY (24+24 MONTHS WARRANTY)",
        image: invertertall150ah,
        specs: [
          { name: "Capacity @C20: 150Ah" },
          {
            name: "Dimensions: Length*Width*Total Height = 510 * 190 * 362 mm (±2 mm)",
          },
          { name: "Warranty: 48 months*" },
        ],
      },
      {
        id: 4,
        name: "OKAYA HADI TALL TUBULAR 150AH INVERTER BATTERY (24+24 MONTHS WARRANTY)",
        image: inverterhaditall150ah,
        specs: [
          { name: "Capacity @C20: 150Ah" },
          {
            name: "Dimensions: Length*Width*Total Height = 510 * 190 * 412 mm (±2 mm)",
          },
          { name: "Warranty: 48 months*" },
        ],
      },
      {
        id: 5,
        name: "OKAYA JUMBO TUBULAR 150AH INVERTER BATTERY (24+24 MONTHS WARRANTY)",
        image: inverterjumbo150ah,
        specs: [
          { name: "Capacity @C20: 150Ah" },
          {
            name: "Dimensions: Length*Width*Total Height = 520 * 279 * 283mm (±2 mm)",
          },
          { name: "Warranty: 48 months*" },
        ],
      },
      {
        id: 6,
        name: "OKAYA JUMBO TUBULAR 160AH INVERTER BATTERY (18+18 MONTHS WARRANTY)",
        image: inverterjumbo160ah,
        specs: [
          { name: "Capacity @C20: 160Ah" },
          {
            name: "Dimensions: Length*Width*Total Height = 520 * 279 * 283mm (±2 mm)",
          },
          { name: "Warranty: 36 months*" },
        ],
      },
      {
        id: 7,
        name: "OKAYA HADI TALL TUBULAR 160AH INVERTER BATTERY (42+24 MONTHS WARRANTY)",
        image: inverterhaditall160ah,
        specs: [
          { name: "Capacity @C20: 160Ah" },
          {
            name: "Dimensions: Length*Width*Total Height = 510 * 190 * 412 mm (±2 mm)",
          },
          { name: "Warranty: 66 months*" },
        ],
      },
      {
        id: 8,
        name: "OKAYA HADI TALL TUBULAR 165AH INVERTER BATTERY (39+24 MONTHS WARRANTY)",
        image: inverterhaditall165ah,
        specs: [
          { name: "Capacity @C20: 165Ah" },
          {
            name: "Dimensions: Length*Width*Total Height = 510 * 190 * 412 mm (±2 mm)",
          },
          { name: "Warranty: 63 months*" },
        ],
      },
      {
        id: 9,
        name: "OKAYA HADI TALL TUBULAR 200AH INVERTER BATTERY (18+18 MONTHS WARRANTY)",
        image: inverterhaditall200ah,
        specs: [
          { name: "Capacity @C20: 200Ah" },
          {
            name: "Dimensions: Length*Width*Total Height = 510 * 190 * 412 mm (±2 mm)",
          },
          { name: "Warranty: 36 months*" },
        ],
      },
    ],
    portfolio_catalogue: [{ name: "Okaya Invertor Battery", url: portfolio }],
    certification: [{ name: "Test Report 100Ah", url: testreport1 }],
  },

  {
    id: 2,
    title: "VRLA battery",
    slug: "vrla-battery",
    desc: "SMF (Sealed Maintenance Free) batteries also known as the Valve Regulated Lead Acid (VRLA) batteries. These flat plate batteries that do not require topping-up and normally do not emit any fumes or gases on a continuous basis. They are completely sealed and therefore eliminate the risk of acid spillage during transportation. Due to their construction these can be mounted in any orientation and do not require constant maintenance. These are used for high end applications of standby power like UPS, Electric Converter, Railway Communications, Security Systems etc. OKAYA batteries have always stood the test of time, year after year due to their robust built and par excellence quality. These are manufactured in-house using the most advanced technology and are known for their all round engineering excellence. Not only is it long lasting and durable but also a product that exudes reliability and sustainability. Each battery is made keeping in mind the highest industry standards and technical specifications. OKAYA SMF batteries are made with the modern Japanese technology, using the in house special lead-tin-calcium alloy and specially imported Absorbent Glass Mat, fabricated in tough ABS containers for increased durability. The latest gas recombination technology ensures no emission of fumes. It promises you a genuine product, which tops in six sigmatic tests and is certified by ETDC.",
    img: vrlaFeature2,
    banner: vrlaBanner,
    advantages: [
      {
        name: "Longer Life",
        desc: "It provides twice the life as compared to the conventional batteries.",
      },
      {
        name: "Extensive Application",
        desc: "Suitable for all your power back up needs.",
      },
      {
        name: "Heat Resistant",
        desc: "High resistance to temperature variation.",
      },
      {
        name: "High Cyclic Application",
        desc: "Best used for frequent and prolonged power outages.",
      },
    ],
    models: [
      {
        id: 1,
        name: "OB28 -12 SMF BATTERY ",
        image: ob28,
        specs: [
          { name: "Dimensions: Length*Width*Total Height =177*168*126mm" },
          {
            name: "Net weight: 8.1 kg (±3%)",
          },
          { name: "Capacity @C20: 28 Ah" },
          { name: "Capacity@C10: 25.5 Ah" },
          { name: "High rate discharge: >27 min." },
        ],
      },
      {
        id: 2,
        name: "OB45-12 SMF BATTERY",
        image: ob45,
        specs: [
          { name: "Dimensions: Length*Width*Total Height =198*166*180mm" },
          {
            name: "Net weight: 14.1 kg (±3%)",
          },
          { name: "Capacity @C20: 45 Ah" },
          { name: "Capacity@C10: 41 Ah" },
          { name: "High rate discharge: >27 min." },
        ],
      },
      {
        id: 3,
        name: "OB65-12 SMF BATTERY",
        image: ob65,
        specs: [
          { name: "Dimensions: Length*Width*Total Height =350*167*179mm" },
          {
            name: "Net weight: 21.5 kg (±3%)",
          },
          { name: "Capacity @C20: 65 Ah" },
          { name: "Capacity@C10: 59.2 Ah" },
          { name: "High rate discharge: >27 min." },
        ],
      },
      {
        id: 4,
        name: "OB100-12 SMF BATTERY",
        image: ob100,
        specs: [
          { name: "Dimensions: Length*Width*Total Height =360*172*240mm" },
          {
            name: "Net weight: 31.4 kg (±3%)",
          },
          { name: "Capacity @C20: 100 Ah" },
          { name: "Capacity@C10: 91.0 Ah" },
          { name: "High rate discharge: >27 min." },
        ],
      },
      {
        id: 5,
        name: "OB150-12 SMF BATTERY",
        image: ob150,
        specs: [
          { name: "Dimensions: Length*Width*Total Height =482*170*244mm" },
          {
            name: "Net weight: 45.3 kg (±3%)",
          },
          { name: "Capacity @C20: 150 Ah" },
          { name: "Capacity@C10: 136.5 Ah" },
          { name: "High rate discharge: >27 min." },
        ],
      },
      {
        id: 6,
        name: "OB200-12 SMF BATTERY",
        image: ob200,
        specs: [
          { name: "Dimensions: Length*Width*Total Height =521*239*237mm" },
          {
            name: "Net weight: 61.0 kg (±3%)",
          },
          { name: "Capacity @C20: 200 Ah" },
          { name: "Capacity@C10: 182.0 Ah" },
          { name: "High rate discharge: >27 min." },
        ],
      },
    ],
    features: [
      {
        name: "Specially designed vent valves",
        desc: "For opening and closing at specified pressure to release extra gas, while simultaneously retaining the required electrolyte quantity in the battery.",
      },
      {
        name: "Tough ABS Containers",
        desc: "Aesthetically designed and fabricated in tough ABS containers for increased durability.",
      },
      {
        name: "Imported Absorbent Glass Mat ",
        desc: "For higher electrolyte immobilization and retention to prevent dry out.",
      },
      {
        name: "Special Lead-tin-calcium alloy",
        desc: " Made using special lead-tin-calcium alloy and special paste formulation to minimize gas emission and prevent water loss.",
      },
      {
        name: "Gas combination technology",
        desc: "Made using the latest gas recombination technology with no emission of fumes.",
      },
      { name: "All Nepal Service Network" },
    ],
    ex_features: [
      {
        name: "SPECIALLY DESIGNED VENT VALVES",
        desc: "For opening and closing at specified pressure to release extra gas, while simultaneously retaining the required electrolyte quantity in the battery.",
        img: vrlaFeature1,
      },
      {
        name: "TOUGH ABS CONTAINERS",
        desc: "Aesthetically designed and fabricated in tough ABS containers for increased durability.",
        img: vrlaFeature2,
      },
      {
        name: "IMPORTED ABSORBENT GLASS MAT",
        desc: "For higher electrolyte immobilization and retention to prevent dry out.",
        img: vrlaFeature3,
      },
      {
        name: "SPECIAL LEAD-TIN-CALCIUM ALLOY",
        desc: "Made using special Lead-tin-calcium Alloy and special Paste Formulation to minimize gas emission and prevent water loss.",
        img: vrlaFeature4,
      },
    ],
    portfolio_catalogue: [{ name: "VLRA Battery", url: vlraPortfolio }],
    certification: [{ name: "OB 26-12", url: vlraReport }],
  },
  {
    id: 3,
    title: "lithium battery",
    slug: "lithium-battery",
    desc: "Lithium batteries are an ideal match for varied applications like solar energy storage, powering E-Vehicles, Telecom & Energy storage systems. In Lithium batteries Lithium is used as Anode, the charge moves from anode to cathode during discharge and cathode to anode during charging Lithium batteries are packed with unique features viz. life extension, energy density, safety, compact size, cost reduction and high charging speed, integrated with high precision Protection Circuit Board ( PCB) used for Battery Management System ( BMS).",
    img: vrlaFeature2,
    banner: lithiumBanner,
    advantages: [],
    features: [],
    ex_features: [],
    portfolio_catalogue: [{ name: "VLRA Battery", url: vlraPortfolio }],
    certification: [{ name: "OB 26-12", url: vlraReport }],
  },

  {
    id: 4,
    title: "E-Rickshaw battery",
    slug: "e-rickshaw-battery",
    desc: "E-rickshaw battery if we unfold this name it comes to be electric rickshaw battery. Okaya is one of the leading brands in domain of batteries and also it is serving best for the development and high pace growth of nation. E-rickshaw battery is gaining more and more popularity these days as it is designed keeping all the necessary points in mind that are related to users and their requirements. Okaya commits to serve the users in the best possible way and following the same since long years. Each and every battery undergoes some specific test so as to confirm the uninterrupted services to users. Products are beautifully designed and tested up to the extent so as they excel the most as far as its performance is concerned. The quality products manufactured under this hoop uses latest technology and defines an unbeatable standard for its parallel companion. E-rickshaw battery price are very manageable and they are decided so as to enhance the level of comfort of users and enrich them with reliable, long-lasting, and efficient batteries. The ultimate goal is to provide one of the most efficient and highly durable batteries with the so frugal E-rickshaw battery price so that anyone and everyone can afford them and leverage up to the most.",
    img: vrlaFeature2,
    banner: rickshawBanner,
    advantages: [],
    features: [
      {
        name: "Constant Supply of Power",
        desc: "It gives a constant supply of power for over 6 hours.",
      },
      {
        name: "Highly Durable",
        desc: "The product is designed for extreme usage in tough conditions.",
      },
      {
        name: "Low maintenance",
        desc: "Does not require frequent maintenance.",
      },
      {
        name: "Long Life",
        desc: "Tubular construction increases the longevity of the battery.",
      },
    ],
    ex_features: [],

    models: [
      {
        id: 1,
        name: "Owner 1400 plus 140 Ah E-rickshaw Tublar Battery 12 month warranty",
        image: ower1400plus,
        specs: [],
      },
      {
        id: 2,
        name: "Ower 1409, 140 Ah E-rickshaw battery 9 months warranty 12 v",
        image: ower1409,
        specs: [],
      },
      {
        id: 3,
        name: "OKAYA OPERT 13506 E-rickshaw battery,120 Ah 6 month warranty Lead acid battery",
        image: ower13506,
        specs: [],
      },
    ],
    portfolio_catalogue: [
      // {"name":"E-Rickshaw battery", "url":rickshawPortfolio},
    ],
    certification: [{ name: " OTER 16006", url: rickshawReport }],
  },
];
