
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './Pages/Home';
import ContactNav from './components/ContactNav';
import Footer from './components/Footer';
import Inverter from './Pages/products/Inverter';
import Battery from './Pages/products/Battery';
import Solar from './Pages/products/Solar';
import Inquiry from './Pages/Support/Inquiry';
import Feedback from './Pages/Support/Feedback'
import HomeService from './Pages/Support/HomeService';
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';

import Notice from './Pages/products/Notice';
import Corporate from './Pages/Corporate';
import Terms from './Pages/Terms';
import Product from './Pages/Product';


function App() {
  return (
    <BrowserRouter>
      {/* <ContactNav /> */}
     
      <Navbar/>
      <Routes>
        <Route path='' element={<Home />} />
        {/* <Route path='/product/inverter' element={<Inverter />} /> */}
        <Route path='/product/:slug' element={<Product />} />
        {/* <Route path='/product/battery' element={<Battery />} /> */}
        {/* <Route path='/product/solar' element={<Solar />} /> */}
        <Route path='/support/Inquiry' element={<Inquiry />} />
        <Route path='/support/Feedback' element={<Feedback />} />
        <Route path='/support/HomeService' element={<HomeService />} />
        <Route path='/aboutus' element={<AboutUs />} />
        <Route path='/contactus' element={<ContactUs />} />
        <Route path='/notice' element={ <Notice/>} />
        <Route path='/corporate/client' element={ <Corporate/>} />
        <Route path='/homeservice' element={ <HomeService/>} />
        <Route path ='/terms' element={<Terms/>} />
<Route path='/Corporate Clients' element={<Corporate/>} />
       
      
      </Routes>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
