import React from "react";
import { Link } from "react-router-dom";
import {
  BsFacebook,
  BsInstagram,
  BsTwitter,
  BsYoutube,
  BsLinkedin,
} from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import {FaWhatsapp} from "react-icons/fa"

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className="">
      <a href="https://wa.me/9779802350695" className=" fixed w-[60px] h-[60px] bottom-[5.3rem] right-7 bg-[#25d366] hover:bg-[#075E54] text-white rounded-full flex content-center items-center shadow-md z-50 transition-colors">
        <FaWhatsapp size={29} className="ml-[1rem]"/>
      </a>
      <div className=" bg-gray-800 text-white  flex   md:flex-row md:gap-[10rem] justify-center  flex-col py-4 pl-4">
        {/*about us*/}

        <div className="grid grid-cols pt-3 leading-8">
          <h1 className=" text-xl p  pb-3 font-bold"> About Go Energy</h1>

          <Link to="/aboutus" onClick={() => window.scroll(0, 0)}>
            {" "}
            About us
          </Link>
          <Link to="/contactus" onClick={() => window.scroll(0, 0)}>
            {" "}
            Contact us
          </Link>
          
          

          {/* <Link to="/homeservice " onClick={() => window.scroll(0, 0)}>
            {" "}
            Home service
          </Link> */}
        </div>

        {/*product*/}

        <div className="grid grid-cols pt-3 leading-8 ">
          <h1 className=" text-xl pb-3 font-bold" onClick={() => window.scroll(0, 0)}>
            {" "}
          Products
          </h1>
          <Link to="/product/inverter-battery" onClick={() => window.scroll(0, 0)}>
            {" "}
            Inverter Battery
          </Link>
          <Link to="/product/vrla-battery" onClick={() => window.scroll(0, 0)}>
            {" "}
            VLRA Battery
          </Link>

          <Link to="/product/e-rickshaw-battery" onClick={() => window.scroll(0, 0)}>
            {" "}
            E-Rickshaw Battery
          </Link>
        </div>
        {/*quicklinks*/}

        <div className="grid grid-cols pt-3  leading-8">
          <h1 className=" text-xl pb-3 font-bold"> Quick Links</h1>

          <Link to="/support/Inquiry" onClick={() => window.scroll(0, 0)}>
            {" "}
            Customer Inquiry
          </Link>
          <Link to="/support/Feedback" onClick={() => window.scroll(0, 0)}>
            {" "}
            Feedback and Complaint
          </Link>
          <Link to="/terms" onClick={() => window.scroll(0, 0)}>
            Terms And Conditions
          </Link>
          {/* <Link to="/Corporate Clients" onClick={() => window.scroll(0, 0)}> Corporate Clients</Link> */}
        </div>
        <div>
          {/*Socil links*/}

          <h1 className="font-bold text-xl  pt-4"> Social </h1>
          <div className="flex gap-3 pt-">
            <a href="https://www.facebook.com/goenergynepal/">
              {" "}
              <BsFacebook size={20} />{" "}
            </a>
            {/* <a href=' '>  <BsInstagram size={20} /> </a>
                        <a href=''> <BsTwitter size={20} />  </a>
                        <a href=''>  < BsYoutube size={20} /> </a>
                        <a href=''>  < BsLinkedin size={20} /> </a>  <br></br> */}
          </div>

          <div className="flex  pt-3">
            <a href="mailto:info@goenergy.com.np">
              <span className="flex gap-2 items-center">
                {" "}
                <HiOutlineMail size={25} />
                info@goenergy.com.np{" "}
              </span>{" "}
            </a>
            {/*   <span className='flex gap-2 items-center'> <RiServiceFill size={25}/> SERVICE : 98010881**</span> */}
          </div>
        </div>
      </div>
      <footer className="flex w-full justify-between items-center px-10 md:px-40 py-2 bg-[#1457a6] text-white flex-col md:flex-row">
        <div>
          <p className="my-2"> Copyright© {year} Go Energy Pvt. Ltd.</p>
        </div>
        <div>
          <p className="my-2">Developed and Maintained by : <a className="text-[#3fde74]" href="https://golden-duck.com">Golden Duck Enterprises</a></p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
