import React from "react";
import { Link } from "react-router-dom";
import { items } from "./AboutData";

import logo from "../images/solar.png";
import okayalogo from "../images/logos/okaya.png";

const About = () => {
  return (
    <div className="">
      <div className="  flex justify-center items-center gap-16 flex-col-reverse md:flex-row px-8 md:px-[10rem] md:py-7">
        <div className="">
          <h1 className=" text-[#1e66b1] font-bold text-5xl 	">
            {" "}
            Go Energy Pvt. Ltd.
          </h1>
          <p className=" text-md 	leading-8 mt-3">
            G.O Energy Pvt.Ltd. operates under the Golcha organization a multifaceted conglomerate. G.O Energy Pvt.Ltd. is one of the leading and fastest growing trading companies that strongly believes in enhancing Nepal’s current energy sector scenario along with its sustainable growth. The company was established in 2019 AD and is the sole distributor of OKaya Inverters, Batteries, Stabilizers and its accessories .The company offers a wide variety of alternative power backup solution products and has largest sales network across the whole country with around 200 plus dealers and service centers in 7 major cities catering the full range service needs of our valued customer.
          </p>
          <p className="text-2xl pt-3 font-bold"> OUR PARTNER IN TECHNOLOGY</p>
          <img src={okayalogo} alt="logo" className=" pt-3" />
        </div>

        <div className="flex justify-center align-center p-2">
          <img src={logo} alt="logo" className=" md:w-[250rem] pt-3" />
        </div>
      </div>

      {items.map((value, index) => (
        <div
          key={index}
          className={`flex justify-center gap-16 ${
            value.flag === "left"
              ? "flex-col-reverse md:flex-row-reverse"
              : "flex-col-reverse md:flex-row"
          } pt-20`}
        >
          <div className="  p-4  md:w-[500px] h-auto  ">
            <h1 className=" text-black  font-bold text-4xl "> {value.name} </h1>
            <p className="pt-2 leading-7"> {value.content}</p>
            <Link
              to={`/product/${value.link}`}
              onClick={() => window.scroll(0, 0)}
            >
              <button className="bg-[#1457a6] text-white  rounded-3xl px-4 py-2 mt-3 }">
                {" "}
                View more{" "}
              </button>
            </Link>
          </div>
          <div>
            <img
              src={value.image}
              alt="logo"
              className="  w-[28rem] h-[20rem]   px-14 md:h-[25rem] md:w-[30rem] mix-blend-multiply"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default About;
