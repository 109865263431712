import React from 'react'
import Hero from '../../components/Hero'
import Form from './Form';

const Feedback = () => {
  const desc = "Feel free to write your feedback and complaint. We're happy to help."
  return (
    <div>
      <Hero title={"Feedback & Complaint"} desc={desc} />
      <div className='  w-full md:flex justify-center items-center'>
      
        <Form type={"Feedback/Complaint"}/>
        
      </div>

    </div>
  )
}

export default Feedback