import React from 'react'
import About from '../components/About'
import Carasol from '../components/Carasol'

const Home = () => {
  return (
    <main>
      <Carasol />
      <About />
    </main>
  )
}

export default Home