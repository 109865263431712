import React from "react";
import { useForm, ValidationError } from '@formspree/react';

const Form = ({type}) => {
  const [state, handleSubmit] = useForm("xrgvadkq");
  if (state.succeeded) {
    return <p className="m-5">Thank you for Your Message! We'll get back to you shortly.</p>;
}
  return (
    <form onSubmit={handleSubmit} class=" bg-gray-100 flex items-center justify-center ">
      <div class="   py-4 md:py-8">
        <div class="grid gap-4  text-sm grid-cols-1 lg:grid-cols-3  ">
          <div class="text-gray-600"></div>

          <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
            <div class="md:col-span-5">
              <label for="full_name">Full Name</label>
              <input
                type="text"
                name="full_name"
                id="full_name"
                class="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
              />
               <ValidationError 
        prefix="Name" 
        field="full_name"
        errors={state.errors}
      />
            </div>

            <div class="md:col-span-5">
              <label for="email">Email Address</label>
              <input
                type="email"
                name="email"
                id="email"
                class="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                // value=""
                placeholder="email@gmail.com"
              />
               <ValidationError 
        prefix="Email" 
        field="email"
        errors={state.errors}
      />
            </div>

            <div class="md:col-span-5">
              <label for="address">Address / Street</label>
              <input
                type="text"
                name="address"
                id="address"
                class="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                // value=""
                placeholder=""
              />
               <ValidationError 
        prefix="Address" 
        field="address"
        errors={state.errors}
      />
            </div>
            <div class="md:col-span-5">
              <label for="phone">Phone no:</label>
              <input
                type="tel"
                name="phone"
                id="phone"
                class="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                // value=""
                placeholder=""
              />
               <ValidationError 
        prefix="Phone" 
        field="phone"
        errors={state.errors}
      />
            </div>
            <div className=" justify-center ">
              <label for="message">{type}</label>
              <textarea
                id="message"
name="message"
                className="border mt-1 rounded px-4  border-gray-200 "
                cols="50"
                rows="7"
              ></textarea>
               <ValidationError 
        prefix="Message" 
        field="Message"
        errors={state.errors}
      />
            </div>

            <div class="md:col-span-5 ">
              <div class="inline-flex items-end">
                <button type="submit" disabled={state.submitting} class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
